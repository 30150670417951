/* کانتینر اصلی اسلایدر */
.slider-container {
  position: relative;
  width: 100%;
  max-height: 600px;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
}

/* کانتینر اسلایدها */
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

/* استایل هر اسلاید */
.slide {
  min-width: 100%;
  opacity: 0; /* تصاویر دیگر پنهان هستند */
  display: none; /* پنهان کردن کامل اسلاید */
  transition: opacity 0.5s ease-in-out;
}

.slide.active {
  opacity: 1; /* تصویر فعال نمایش داده می‌شود */
  display: block; /* نمایش اسلاید فعال */
}

.slider img {
  width: 100%;
  height: auto;
  display: block;
}

/* دکمه‌های جهت‌دهی */
.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #78938A;
  color: #F1DDBF;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev:hover,
.next:hover {
  background-color: #78938A;
}
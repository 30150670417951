.articles-section {
    background-color: #F1DDBF;
    padding: 40px 20px;
    text-align: center;
  }
  
  .articles-section h2 {
    color: #525E75;
    margin-bottom: 30px;
  }
  
  .articles-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .article {
    background-color: #92BA92;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .article h3 {
    color: #525E75;
    margin-bottom: 10px;
  }
  
  .article p {
    color: #78938A;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .article a {
    display: inline-block;
    margin-top: 10px;
    color: #525E75;
    text-decoration: underline;
  }
  
  .article a:hover {
    color: #78938A;
  }
  
/* src/styles/Contact.css */
.contact-section {
    padding: 20px;
    background-color: #92BA92;
    color: #525E75;
    text-align: center;
  }
  
  .contact-section h2 {
    font-size: 24px;
  }
  
  .contact-section p {
    font-size: 18px;
  }
  
.footer {
  background-color: #525E75;
  color: #F1DDBF;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}

.footer-contact {
  margin-bottom: 20px;
}

.footer-contact h3 {
  margin-bottom: 10px;
  color: #92BA92;
}

.footer-contact p {  
  margin: 5px 0;
}

.footer-contact a {
  color: #F1DDBF;
  text-decoration: underline;
}

.footer-contact a:hover {
  color: #78938A;
}

.footer-copyright {
  border-top: 1px solid #78938A;
  padding-top: 10px;
  font-size: 12px;
}
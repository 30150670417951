.navbar {
  display: flex;
  justify-content: space-between; /* عناصر را به چپ و راست تقسیم می‌کند */
  align-items: center;
  padding: 10px 20px;
  background-color: #525E75;
  color: #F1DDBF;
}

.navbar-logo {
  margin-left: auto; /* اطمینان از قرارگیری لوگو در سمت راست */
}

.navbar-logo a {
  font-size: 24px;
  font-weight: bold;
  color: #F1DDBF;
  text-decoration: none;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0 auto; /* لینک‌ها در وسط قرار می‌گیرند */
}

.navbar-links li a {
  color: #F1DDBF;
  text-decoration: none;
  font-size: 16px;
}

.navbar-actions {
  display: flex;
  align-items: center;
  gap: 15px; /* فاصله بین دکمه ورود و آیکون سبد خرید */
}

.btn {
  background-color: #92BA92;
  color: #FFFFFF;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn:hover {
  background-color: #78938A;
}

.cart-icon {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cart-icon img {
  width: 24px;
  height: 24px;
}

.cart-count {
  position: absolute; /* موقعیت شماره سبد خرید را مشخص می‌کند */
  top: -2px; /* فاصله از بالای آیکون */
  right: -19px; /* فاصله از سمت راست آیکون */
  background-color: #ff6347; /* رنگ پس‌زمینه برای شماره */
  color: white; /* رنگ متن شماره */
  border-radius: 50%; /* گرد کردن پس‌زمینه */
  padding: 3px 6px; /* فاصله داخلی */
  font-size: 12px; /* اندازه فونت */
  font-weight: bold; /* ضخامت فونت */
  z-index: 1;
}

/* src/styles/App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #F1DDBF;
    color: #525E75;
  }
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  footer {
    margin-top: auto;
  }
  
  h2 {
    color: #333;
  }
  
/* src/styles/FooterInfo.css */
.footer-info {
    background-color: #f8f9fa;
    padding: 20px 40px;
    border-top: 1px solid #ddd;
  }
  
  /* بخش آیکون‌ها */
  .footer-icons-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .icon-item {
    text-align: center;
    flex: 1;
  }
    
  
  .icon-item i {
    width: 35px;
    height: 25px;
    margin-bottom: 10px;
    display: inline-block;
    background-image: url(../assets/images/slices.png);
  }
  
  .icon-item p {
    font-size: 14px;
    color: #555;
  }
  
  /* بخش لینک‌ها */
  .footer-links-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .footer-section h4 {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section li {
    color: #555;
    margin-bottom: 5px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .footer-section li:hover {
    color: #007bff;
  }

  .original-guarantee img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .original-guarantee p {
    font-size: 14px;
    color: #555;
  }
  
  .social-media h4 {
    font-size: 16px;
    color: #333;
    margin: 20px 0 10px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .social-icons i {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.3s;
    display: inline-block;
    background-image: url(../assets/images/slices.png);
  }
  
  .social-icons img:hover {
    transform: scale(1.2);
  }
  
  .newsletter p {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .email-form {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .email-form input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .email-form button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .email-form button:hover {
    background-color: #0056b3;
  }
  
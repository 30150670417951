/* src/styles/fonts.css */
@font-face {
    font-family: 'Iran Sans';
    src: url('../assets/fonts/IRANSansWeb.woff2') format('woff2'),
         url('../assets/fonts/IRANSansWeb.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Iran Sans Black';
    src: url('../assets/fonts/IRANSansWeb_Black.woff2') format('woff2'),
         url('../assets/fonts/IRANSansWeb_Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  